.app {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}

.app__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.app__body > :nth-child(odd) {
  background-color: rgb(242, 242, 242);
}

.app-body__item {
  display: flex;
  flex: 1;

  width: 100%;
  height: 50%;
}
