.copyright-section {
  display: flex;
  width: 100%;
  height: 2.5rem;
  align-items: center;
  justify-content: flex-end;

  color: #f2f2f2;

  background-color: #666666;
  padding: 0 1rem;

  border-top: thin solid #f2f2f2;
}

.copyright-section__claim {
  font-size: 1.6rem;
}

@media only screen and (max-width: 576px) {
  .copyright-section__claim {
    font-size: 1.2rem;
  }
}
