.section {
  --child-width: 95rem;

  display: flex;
  width: 100%;
  min-height: 52rem;

  align-items: center;
  justify-content: center;

  padding: 0 1rem;
}

.section > * {
  width: 100%;
  max-width: var(--child-width);
}

.section--standard > div,
img {
  display: flex;
  flex: 1;
  flex-basis: 0;
}

.section--reverse {
  flex-direction: row-reverse;
}

.section--column {
  flex-direction: column;
}

.section--mobile {
  flex-direction: column;
}

.section--mobile > :not(:last-child) {
  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .section {
    --child-width: 70rem;
  }
}
