.header-section {
  --headline-fs: 5.4rem;
  --dot: 1.8rem;

  display: flex;
  max-width: 105rem;

  align-items: center;
  justify-content: space-between;

  gap: 4rem;
}

.section-banner {
  background-position: top left;
  background-image: url('../../../../style/assets/banner.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.header-section--mobile > :not(:last-child) {
  margin-bottom: 2rem;
}

.header-section__headline {
  width: 44.5rem;
}

.header-section__headline__title {
  font-size: var(--headline-fs);
  text-align: center;
  font-weight: 900;
}

.header-section__headline__subtitle__wrapper {
  width: 90%;
  position: relative;
  height: 1.8rem;

  transform: translateX(2.5rem);
}

.header-section__headline__subtitle {
  position: absolute;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding-top: 0.8rem;

  color: #666;

  opacity: 0;
}

.header-section__dot {
  display: inline-block;
  height: var(--dot);
  width: var(--dot);

  background-color: #bbb;

  border-radius: 50%;
}

.header-section__service-list1 {
  animation: exchangeText1 15s infinite;
}

.header-section__service-list2 {
  animation: exchangeText2 15s infinite;
}

@keyframes exchangeText1 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes exchangeText2 {
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 950px) {
  .header-section {
    --headline-fs: 5.2rem;

    flex-direction: column;
    padding: 2.5rem 0;
  }
}

@media only screen and (max-width: 450px) {
  .header-section__headline {
    width: initial;
  }

  .header-section {
    --headline-fs: 4rem;
    --dot: 1.2rem;
  }

  .header-section__headline__subtitle {
    font-size: 1.4rem;
  }
}
