.review-section {
  display: flex;
  flex-direction: column;
  padding: 5rem 1rem;
}

.review-section__headline {
  text-align: center;

  margin-bottom: 2.4rem;
}

.review-section__rating {
  display: inline-flex;
  justify-content: flex-end;

  font-style: italic;

  padding-right: 2rem;
  margin-bottom: 0.8rem;
}
