.card {
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  min-width: 31rem;
  width: 80%;
  height: 30rem;

  background-color: white;

  border-radius: 1.6rem;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.card__additional-info {
  align-self: flex-start;
  margin-left: 1rem;
  padding: 1rem 0;
}

.card__additional-info__tooltip {
  max-width: 32rem;
  border-radius: 1.5rem;
  background-color: #bfbfbf;
  padding: 1.5rem;

  font-size: 1.4rem;
}

.card__header {
  display: flex;
  width: 100%;
  height: 7rem;

  justify-content: center;
  align-items: center;

  color: black;

  background-color: #bfbfbf;
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
}

.card__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  padding: 0.8rem 1.6rem;
}

.card__body > :not(:last-child) {
  margin-bottom: 1.6rem;
}

.card__body__time-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card__body__time-slot__title {
  margin-bottom: 0.8rem;
}

.card__body__time-slot__subtitle {
  font-size: 1.6rem;
}

.card__footer {
  display: inline-flex;
  height: 3.5rem;

  align-items: center;
  justify-content: center;

  font-size: 1.8rem;
  font-weight: 500;
}
