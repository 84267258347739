html {
  font-size: 62.5%;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-padding: 5rem 20%;
  --main-padding-mobile: 3.5rem 10%;

  font-family: 'Source Serif 4', sans-serif;
  font-weight: 600;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}

p {
  font-size: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Merriweather', sans-serif;
  letter-spacing: 0.2rem;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-size: 1.92rem;
}

@media only screen and (max-width: 576px) {
  :root {
    --main-padding: 3.5rem 5%;
  }

  h1 {
    font-size: 4.2rem;
  }
}
