.box {
  min-width: 0;
  max-width: 320px;

  padding: 8px;

  border-radius: 12px;
  border: 2px solid transparent;
}

.box:hover {
  border-color: #a10f15;
}

.box__img {
  margin-bottom: 0.6rem;
  min-width: 0;
  max-width: 100%;
  height: auto;

  border-radius: 6px;
}

.box__date-and_read-time {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #5f6c80;
}

.box__title-wrapper {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.box__title-wrapper__title {
  font-size: 2rem;
  color: #2d3748;
  line-height: 3rem;
}

.hvr-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-float:hover,
.hvr-float:focus,
.hvr-float:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

@media only screen and (min-width: 640px) {
  .box__title-wrapper__title {
    font-size: 2rem;
  }
  .box__date-and_read-time {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 768px) {
  .box__title-wrapper__title {
    font-size: 2.4rem;
  }
  .box__date-and_read-time {
    font-size: 2rem;
  }
}
