.about-us-section {
  --min-width: 10rem;
  min-height: 52rem;

  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 1rem;
  gap: 1rem;
}

.about-us-section > div {
  flex: 1 1 0;
}

.about-us-section__pic {
  --pic-width: 460px;
  --pic-height: 460px;
  width: var(--pic-width);
  height: var(--pic-height);
  border-radius: 29px;
}

@media screen and (max-width: 963px) {
  .about-us-section {
    flex-direction: column;
  }

  .about-us-section__pic {
    --pic-width: 100%;
  }
}
