.rating {
  display: flex;
  width: fit-content;
}

.rating--pulsing {
  animation: pulse 2s infinite;
}

.rating > :not(:last-child) {
  margin-right: 0.8rem;
}

.rating__stars {
  display: flex;
  height: fit-content;
  width: fit-content;

  justify-content: center;
}

.rating__stars > :nth-child(even) {
  margin-right: -0.8rem;
}

.rating__stars > button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: inherit;

  overflow: hidden;
}

.rating__stars--right-star > svg {
  transform: translateX(-50%);
}

.rating__stars--left-star,
.rating__stars--left-star {
  width: 0.8rem;
  overflow: hidden;
}

.rating__stars--selected > svg {
  fill: #ffd700;
}

.rating__evaluation {
  align-self: center;

  color: #005ea8;
  font-size: 1.4rem;
}

.rating__evaluation:hover {
  color: red;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
