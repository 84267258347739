.our-vision-section {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.our-vision-section__pic {
  --pic-width: 460px;
  --pic-height: 460px;
  width: var(--pic-width);
  height: var(--pic-height);
  border-radius: 29px;
}

@media screen and (max-width: 963px) {
  .our-vision-section__pic {
    --pic-width: 100%;
  }
}
