.blog-post {
  width: 100%;
  min-width: 0;
  max-width: 1024px;

  margin: 0 auto;
  padding: 1.6rem;
}

.blog-post__title {
  min-width: 0;
  font-size: 6.4rem;
  font-weight: 700;
  color: black;

  margin: 3.2rem 0 0.4rem;
  overflow-wrap: break-word;
}

.blog-post__subtitle {
  font-size: 2rem;
  color: #5f6c80;

  margin: 1.6rem 0 2rem;
}

.blog-post__section {
  margin: 6.4rem 0;
}

.blog-post__section__img {
  width: 100%;
  height: auto;
  box-shadow: transparent 0px 0px 0px 400px inset;
}

.blog-post__section > p {
  font-size: 2rem;
  color: #2d3748;
  line-height: 2.6rem;
  word-break: break-word;
  letter-spacing: -0.003em;

  margin: 2rem 0;
}

.blog-post__section__photograph {
  display: flex;
  width: 100%;
  justify-content: center;

  white-space: pre-wrap;
  font-size: 1.33rem;

  margin: 0.8rem 0;
}

.blog-post__section__photograph > a {
  color: #a10f15;
  font-size: 1.33rem;
}

@media only screen and (min-width: 640px) {
  .blog-post {
    padding: 3.2rem;
  }
}

.blog-post__section__socials {
  height: 32px;
  margin-bottom: 8px;
}

.blog-post__section__socials > :not(:last-child) {
  margin-right: 8px;
}

.blog-post__section__socials__fb {
  height: 32px;
}

.blog-post__section__socials__fb::hover {
  cursor: pointer;
}
