.table {
  width: 100%;
  height: fit-content;
  min-width: 20rem;
  max-width: 50rem;

  margin: 2.5rem 0;

  font-size: 1.44rem;
  font-family: sans-serif;

  border-collapse: collapse;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.table thead tr {
  background-color: #bfbfbf;
  color: #ffffff;
  text-align: left;
  font-size: 1.8rem;
}

.table th,
.table td {
  padding: 1.2rem 1.5rem;
  color: black;
}

.table__right-column {
  width: fit-content;

  text-align: right;
  white-space: nowrap;
}

.table th:first-of-type {
  border-top-left-radius: 0.6rem;
}

.table th:last-of-type {
  border-top-right-radius: 0.6rem;
}

.table tbody tr {
  border-bottom: thin solid #bfbfbf;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 0.2rem solid #bfbfbf;
}

.table__row--active {
  font-weight: bold;
  color: #bfbfbf;
}

.table__row--extra-info {
  font-size: 1.2rem;
}
