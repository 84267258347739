.article__wrapper {
  padding: 5rem 1rem;
}

.article {
  display: flex;
  max-width: 50rem !important;
  width: 100%;
  flex-direction: column;

  align-self: center;

  align-items: center;
  justify-content: center;

  text-align: center;
  white-space: pre-line;

  margin-left: auto;
}

.article__text {
  font-size: 2.4rem;
  font-weight: 400;
  font-style: italic;
  line-height: 3rem;
}

.article__text--hide-paragraph {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.article__text__read-more {
  width: fit-content;
  align-self: flex-end;

  background-color: transparent;

  color: #005ea8;
  border: none;

  cursor: pointer;
}

.article__text__read-more:hover {
  color: red;
}
