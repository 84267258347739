.footer-section {
  display: flex;
  width: 100%;
  height: 24rem;

  background-color: #bfbfbf;

  padding: 5rem 2rem;

  font-size: 1.4rem;
}

.footer-section > div {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 6rem;
}

.footer-section__column > span:first-of-type {
  margin-bottom: 0.8rem;

  font-size: 1.6rem;
}

.footer-section__column > :not(:last-child) {
  margin-bottom: 0.4rem;
  margin-right: 2rem;
}

.footer-section__column > :last-child {
  margin-bottom: 1.2rem;
}

@media only screen and (max-width: 576px) {
  .footer-section {
    flex-direction: column;
    height: fit-content;
  }

  .footer-section > :last-child {
    margin-top: 2.4rem;
  }
}
