.prices-section {
  display: grid;

  grid-auto-flow: row;
  grid-template-areas: 'left right';
  grid-template-columns: repeat(2, minmax(0, 1fr));

  column-gap: 6rem;
}

.prices-section__wrapper {
  padding: 5rem 1rem;
}

.prices-section__title {
  font-size: 9.6rem;
  margin-bottom: 7.2rem;
  text-align: center;
}

.prices-section__title > strong {
  font-size: inherit;
}

.prices-section__disclaimer {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;

  font-style: italic;
  font-size: 1.2rem;
}

@media only screen and (max-width: 1000px) {
  .prices-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .prices-section > table {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .prices-section__wrapper {
    padding: 5rem 5rem;
  }
}

@media only screen and (max-width: 700px) {
  .prices-section__title {
    font-size: 5.6rem;
    margin-bottom: 3.6rem !important;
  }

  .prices-section > table {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .prices-section__wrapper {
    padding: 5rem 1rem;
  }
}

@media only screen and (max-width: 420px) {
  .prices-section__title {
    font-size: 4.8rem;
  }
}
