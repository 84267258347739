.comparison-section {
  --width: 950px;

  max-width: var(--width);
  padding: 0 1rem;
}

@media only screen and (max-width: 950px) {
  .comparison-section {
    --width: 100%;
  }
}

@media only screen and (max-width: 725px) {
  .comparison-section {
    --width: 600px;
  }
}

@media only screen and (max-width: 576px) {
  .comparison-section {
    --width: 400px;
  }
}

@media only screen and (max-width: 420px) {
  .comparison-section {
    --width: 100%;
  }
}
