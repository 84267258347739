.review {
  display: flex;
  flex-direction: column;
}

.review::after {
  content: '';

  width: 100%;
  height: 0.1rem;
  margin-top: 1.6rem;

  background: #ccc;
}

.review__username-rating {
  display: inline-flex;
  justify-content: space-between;

  color: #3093d6;
  font-size: 1.6rem;

  margin-bottom: 0.8rem;
}

.review__text {
  width: 90%;
  font-size: 1.4rem;
}
