.header {
  --header-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15);

  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;

  position: sticky;
  top: 0;

  height: 6.5rem;
  background-color: rgb(255, 255, 255);

  padding: 0 5rem;
  box-shadow: var(--header-shadow);

  z-index: 9999;
}

.header > * {
  display: flex;
  align-items: center;
  flex: 1;
  flex-basis: 0;
}

.header__main__left__btn {
  border: none;
  background-color: transparent;

  cursor: pointer;
}

.header__main__center {
  justify-content: center;
}

.header__main__logo {
  width: 16.2rem;
  height: 16.2rem;
  object-fit: fill;
  margin-top: 0.5rem;
  cursor: pointer;
}

.header__main__right {
  justify-content: flex-end;
}

.header__main__right > ul > li {
  list-style-type: none;
  font-style: italic;

  text-decoration: underline;
}

.header__menu {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  background-color: rgb(255, 255, 255);

  position: absolute;
  top: 6.5rem;
  right: 0;

  padding: 1.4rem 5rem;
  box-shadow: var(--header-shadow);
}

.header__menu--hidden {
  display: none;
}

.header__menu__list {
  display: flex;
  list-style: none;

  justify-items: center;
}

.header__menu__list--mobile {
  flex-direction: column;
}

.header__menu__list > :not(:last-child) {
  margin-right: 1.6rem;
}

.header__menu__list__item {
  display: flex;
  height: 3.6rem;
  align-items: center;

  padding: 0.8rem;

  cursor: pointer;
}

.header__menu__list__item:hover {
  color: grey;
}

@media only screen and (max-width: 576px) {
  .header {
    padding: 0 2.5rem;
  }

  .header__menu {
    padding: 1.4rem 2.5rem;
  }
}
