.explanation-section__text {
  display: flex;
  flex-direction: column;

  padding: 5rem 1rem;

  align-items: center;
  justify-content: center;
}

.explanation-section__text > p {
  text-align: center;
}

.explanation-section__title {
  display: inline-flex;
  width: 100%;

  justify-content: center;

  margin-top: 2.4rem;
  margin-bottom: 0.8rem;

  font-size: 2.6rem;
  font-weight: bold;
}

.explanation-section__services {
  align-self: baseline;
  margin-left: 1.6rem;
}

.explanation-section__services > li {
  padding-left: 1rem;
  font-size: 2rem;
  font-style: italic;
}

.explanation-section__services > li::marker {
  /* content: "➦"; */
  content: '➭';
}
