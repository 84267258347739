.blog {
  display: flex;
  width: 100%;
  height: 100%;

  margin: 0 auto;
}

.blog-screen {
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 6rem;
  padding: 3.2rem;
}

.blog-screen__header {
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.blog-layout {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;
}

@media only screen and (max-width: 640px) {
  .blog-screen {
    padding: 1.6rem;
  }

  .blog-layout {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 640px) {
  .blog-layout {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1024px) {
  .blog-layout {
    grid-template-columns: repeat(3, 1fr);
  }
}
