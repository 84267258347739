.review-list__container {
  display: flex;
  flex-direction: column;
}

.review-list__container > :not(:last-child) {
  margin-bottom: 1.6rem;
}

.review-list {
  padding: 0 1.6rem;
}

.review-list > :not(:last-child) {
  margin-bottom: 1.6rem;
}

.review-list::-webkit-scrollbar {
  width: 0.4rem;
}

.review-list::-webkit-scrollbar-track {
  background: transparent;
}

.review-list::-webkit-scrollbar-thumb {
  background: #ccc;

  border-radius: 2rem;
  border: transparent;
}

.review-list__load-more {
  background-color: rgba(56, 140, 79, 0.3);

  border: 0.1rem #32633f solid;
  border-radius: 0.8rem;

  padding: 0.8rem 1.6rem;
  margin: 0 auto;

  color: rgba(56, 140, 79, 1);
  font-size: 2rem;
  font-weight: bold;
}

.review-list__load-more:hover {
  cursor: pointer;

  background-color: rgba(56, 140, 79, 0.5);
}

.review-list__footer {
  display: inline-flex;
  width: 100%;

  justify-content: flex-end;

  font-size: 1.2rem;

  margin-top: 0.8rem;
  padding-right: 2rem;
}

.review-list__footer > a {
  color: #005ea8;
  font-size: inherit;
}

.review-list__footer > a:hover {
  color: red;
}
